import { Table, TableList, TableItem } from "components/Table"
import { BadgeStatus } from "components/Badge"
import If from "components/If"
import Card from "components/Card"
import EmptyState from "components/EmptyState"
import Pagination from "components/Pagination"

import { WALLET_DETAILS_HEADERS as HEADERS } from "./constants"

import "./Table.styl"

const WalletDetailsTable = ({
  listInstallment = [],
  pagination = {},
  onPagination = () => {},
}) => {
  return (
    <div className="wallet-details-table">
      <If
        condition={listInstallment?.length}
        renderIf={
          <Table headers={HEADERS}>
            {listInstallment?.map((Installment, index) => (
              <TableList key={index} data-test="table-row">
                <TableItem
                  withoutPadding
                  className="centered"
                  data-test="parcela"
                  data-label="Número da parcela"
                >
                  {Installment?.number}
                </TableItem>
                <TableItem
                  withoutPadding
                  className="centered"
                  data-test="data de vencimento"
                  data-label="Data de vencimento"
                >
                  {Installment?.due}
                </TableItem>
                <TableItem
                  withoutPadding
                  className="centered"
                  data-test="valor da parcela"
                  data-label="Valor da parcela"
                >
                  {Installment?.value}
                </TableItem>
                <TableItem
                  withoutPadding
                  className="centered"
                  data-label="Situação"
                >
                  <BadgeStatus
                    data-test="situação"
                    description={Installment?.status}
                    status={Installment?.status_color}
                  />
                </TableItem>
              </TableList>
            ))}
          </Table>
        }
        renderElse={
          <Card className="wallet-details-table__card">
            <EmptyState
              className="wallet-details-table__empty"
              text="Nenhuma parcela disponível"
            />
          </Card>
        }
      />
      <Pagination
        currentPage={pagination?.current_page}
        pages={pagination?.total_pages}
        onChange={onPagination}
      />
    </div>
  )
}

export default WalletDetailsTable
