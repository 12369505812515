import "./Divider.styl"

const Divider = ({
  opacity = 0.1,
  margin = "5px 0",
  thickness = "1px",
  color = "secondary-lightest",
}) => {
  return (
    <hr
      className="content-divider"
      style={{
        margin,
        opacity,
        height: thickness,
        color: `var(--color-${color})`,
        backgroundColor: `var(--color-${color})`,
      }}
    />
  )
}

export default Divider
