import If from "components/If"
import Divider from "../Divider"
import Card from "components/Card"
import EmptyState from "components/EmptyState"
import ReadOnlyField from "../ReadOnlyField/index"

import "./DetailsCard.styl"

const WalletDetailsCard = ({
  client,
  contract,
  guarantor,
  validSectionsCounter = 0,
}) => {
  return (
    <div className="wallet-details-card">
      <If
        condition={!!client || !!contract || !!guarantor}
        renderIf={
          <>
            <If condition={!!client}>
              <section className="wallet-details-card__section">
                <span className="wallet-details-card__section--title">
                  Dados do cliente
                </span>

                <ReadOnlyField
                  label="Nome completo"
                  content={client?.full_name}
                  className="wallet-details-card__field"
                />

                <div className="wallet-details-card__field--row">
                  <ReadOnlyField
                    label="CPF"
                    content={client?.document_number}
                    className="wallet-details-card__field wallet-details-card__field--half"
                  />

                  <ReadOnlyField
                    label="Telefone"
                    content={client?.phone_number}
                    className="wallet-details-card__field wallet-details-card__field--half"
                  />
                </div>

                <ReadOnlyField
                  label="Email"
                  content={client?.email}
                  className="wallet-details-card__field"
                />

                <ReadOnlyField
                  label="Endereço"
                  content={client?.address}
                  className="wallet-details-card__field"
                />
              </section>
            </If>

            <If condition={validSectionsCounter >= 2}>
              <Divider margin="16px 0" />
            </If>

            <If condition={!!guarantor}>
              <section className="wallet-details-card__section">
                <span className="wallet-details-card__section--title">
                  Dados do Avalista
                </span>

                <ReadOnlyField
                  label="Nome completo"
                  content={guarantor?.full_name}
                  className="wallet-details-card__field"
                />

                <div className="wallet-details-card__field--row">
                  <ReadOnlyField
                    label="CPF"
                    content={guarantor?.document_number}
                    className="wallet-details-card__field wallet-details-card__field--half"
                  />

                  <ReadOnlyField
                    label="Telefone"
                    content={guarantor?.phone_number}
                    className="wallet-details-card__field wallet-details-card__field--half"
                  />
                </div>

                <ReadOnlyField
                  label="Email"
                  content={guarantor?.email}
                  className="wallet-details-card__field"
                />

                <ReadOnlyField
                  label="Endereço"
                  content={guarantor?.address}
                  className="wallet-details-card__field"
                />
              </section>
            </If>

            <If condition={validSectionsCounter === 3}>
              <Divider margin="16px 0" />
            </If>

            <If condition={!!contract}>
              <section className="wallet-details-card__section">
                <span className="wallet-details-card__section--title">
                  Contrato
                </span>

                <div className="wallet-details-card__field--row">
                  <ReadOnlyField
                    label="Número do contrato"
                    content={contract?.number}
                    className="wallet-details-card__field wallet-details-card__field--half"
                  />

                  <ReadOnlyField
                    label="Situação do contrato"
                    content={contract?.status}
                    contentColor={contract?.status_color}
                    className="wallet-details-card__field wallet-details-card__field--half"
                  />
                </div>

                <div className="wallet-details-card__field--row">
                  <ReadOnlyField
                    label="Faixa de atraso"
                    content={contract?.delay_track_rating}
                    className="wallet-details-card__field wallet-details-card__field--half"
                  />

                  <ReadOnlyField
                    label="Dias de atraso"
                    content={contract?.amount_days_delay}
                    className="wallet-details-card__field wallet-details-card__field--half"
                  />
                </div>

                <div className="wallet-details-card__field--row">
                  <ReadOnlyField
                    label="Tipo de contrato"
                    content={contract?.type}
                    className="wallet-details-card__field wallet-details-card__field--half"
                  />

                  <ReadOnlyField
                    label="Data da contratação"
                    content={contract?.contract_date}
                    className="wallet-details-card__field wallet-details-card__field--half"
                  />
                </div>

                <div className="wallet-details-card__field--row">
                  <ReadOnlyField
                    label="Volume financiado"
                    content={contract?.amount}
                    className="wallet-details-card__field wallet-details-card__field--half"
                  />

                  <ReadOnlyField
                    label="Taxa de juros"
                    content={contract?.nominal_interest_rate}
                    className="wallet-details-card__field wallet-details-card__field--half"
                  />
                </div>

                <ReadOnlyField
                  label="Saldo devedor"
                  content={contract?.balance_due}
                  className="wallet-details-card__field"
                />
              </section>
            </If>
          </>
        }
        renderElse={
          <Card className="wallet-details-card__card">
            <EmptyState
              className="wallet-details-card__empty"
              text="Nada por aqui"
            />
          </Card>
        }
      />
    </div>
  )
}

export default WalletDetailsCard
