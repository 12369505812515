import { Desktop, Mobile } from "components/Devices"
import DesktopScreen from "./components/Desktop"
import MobileScreen from "./components/Mobile"

const WalletDetails = ({ pageContext: { business } }) => {
  return (
    <>
      <Desktop>
        <DesktopScreen pageContext={{ business }} />
      </Desktop>
      <Mobile>
        <MobileScreen pageContext={{ business }} />
      </Mobile>
    </>
  )
}

export default WalletDetails
