import { navigate } from "gatsby"
import Can from "components/Can"
import Icon from "components/Icon"
import DetailsTable from "../Table"
import Redirect from "components/Redirect"
import useWalletDetails from "../../hooks"
import PageHeader from "components/PageHeader"
import DetailsAccordion from "../DetailsAccordion"
import { useParameter } from "hooks/useParameters"

import "./WalletDetails.styl"

const WalletDetailsMobile = ({ pageContext: { business } }) => {
  const [contract_id] = useParameter("contract_id")
  const { client, contract, guarantor, validSectionsCounter } =
    useWalletDetails({
      contract_id,
    })

  if (!contract_id) return <Redirect to={ROUTES.home.path} />

  return (
    <Can
      business={business.business_permission}
      see="wallet-management"
      otherwise={{ goTo: ROUTES.home.path }}
    >
      <button onClick={() => navigate(-1)} className="wallet-details__back">
        <Icon name="arrow-left" />
        Voltar
      </button>
      <header className="wallet-details__header">
        <PageHeader title="Carteiras" />
      </header>

      <div className="wallet-details">
        <DetailsAccordion
          client={client}
          contract={contract}
          guarantor={guarantor}
          validSectionsCounter={validSectionsCounter}
        />
        <DetailsTable listInstallment={contract?.installments || []} />
      </div>
    </Can>
  )
}

export default WalletDetailsMobile
