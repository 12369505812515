import { useCallback, useEffect, useRef, useState } from "react"

import services from "services"

const useWalletDetails = ({ contract_id }) => {
  const isMountedRef = useRef(null)
  const [client, setClient] = useState()
  const [contract, setContract] = useState()
  const [guarantor, setGuarantor] = useState()
  const [validSectionsCounter, setValidSectionsCounter] = useState(0)

  const handleSectionsCounter = (details) => {
    const toTruhlyItemsSection = (hasSection) => hasSection === true

    const arrayBoleanData = [
      !!details?.client,
      !!details?.contract,
      !!details?.guarantor,
    ]
    const getLengthAvaiableFields =
      arrayBoleanData.filter(toTruhlyItemsSection).length

    return getLengthAvaiableFields
  }

  const handleFetch = useCallback((details) => {
    if (isMountedRef.current) {
      setClient(details?.client)
      setContract(details?.contract)
      setGuarantor(details?.guarantor)
      setValidSectionsCounter(handleSectionsCounter(details))
    }
  }, [])

  const fetchWalletDetails = useCallback(
    (props) => {
      services.microloans.wallet.getDetails({ contract_id }).then(handleFetch)
    },
    [contract_id, handleFetch]
  )

  useEffect(() => {
    isMountedRef.current = true

    fetchWalletDetails()

    return () => (isMountedRef.current = false)
  }, [fetchWalletDetails])

  return {
    client,
    contract,
    guarantor,
    validSectionsCounter,
  }
}

export default useWalletDetails
