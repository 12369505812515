import classnames from "classnames"
import If from "components/If"

import "./ReadOnlyField.styl"

const ReadOnlyField = ({
  label = "",
  content = "",
  className = "",
  contentColor = "black",
  labelColor = "gray-dark",
  hasElipseInLabel = false,
  hasElipseInContent = false,
  isLabelCentered = false,
  isContentCentered = false,
}) => {
  return (
    <If condition={content}>
      <div className={classnames("read-only-field", className)}>
        <div
          className={classnames("read-only-field__label", {
            "read-only-field__label--elipse": hasElipseInLabel,
            "read-only-field__label--centered": isLabelCentered,
          })}
          style={{ color: `var(--color-${labelColor})` }}
        >
          {label}
        </div>

        <div
          className={classnames("read-only-field__content", {
            "read-only-field__content--elipse": hasElipseInContent,
            "read-only-field__content--centered": isContentCentered,
          })}
          style={{ color: `var(--color-${contentColor})` }}
        >
          {content}
        </div>
      </div>
    </If>
  )
}

export default ReadOnlyField
