import If from "components/If"
import Icon from "components/Icon"
import Card from "components/Card"
import EmptyState from "components/EmptyState"
import ReadOnlyField from "../ReadOnlyField/index"

import "./DetailsAccordion.styl"

const WalletDetailsAccordion = ({ client, contract, guarantor }) => {
  return (
    <div className="wallet-details-accordion">
      <If
        condition={!!client || !!contract || !!guarantor}
        renderIf={
          <>
            <If condition={!!client}>
              <details className="wallet-details-accordion__card">
                <summary className="wallet-details-accordion__card--title">
                  Dados do Cliente
                  <Icon
                    name="arrow-right"
                    className="wallet-details-accordion__card--arrow"
                  />
                </summary>

                <div className="wallet-details-accordion__card--content">
                  <ReadOnlyField
                    label="Nome completo"
                    content={client?.full_name}
                    className="wallet-details-accordion__field"
                  />

                  <div className="wallet-details-accordion__field--row">
                    <ReadOnlyField
                      label="CPF"
                      content={client?.document_number}
                      className="wallet-details-accordion__field wallet-details-accordion__field--half"
                    />

                    <ReadOnlyField
                      label="Telefone"
                      content={client?.phone_number}
                      className="wallet-details-accordion__field wallet-details-accordion__field--half"
                    />
                  </div>

                  <ReadOnlyField
                    label="Email"
                    content={client?.email}
                    className="wallet-details-accordion__field"
                  />

                  <ReadOnlyField
                    label="Endereço"
                    content={client?.address}
                    className="wallet-details-accordion__field"
                  />
                </div>
              </details>
            </If>

            <If condition={!!guarantor}>
              <details className="wallet-details-accordion__card">
                <summary className="wallet-details-accordion__card--title">
                  Dados do Avalista
                  <Icon
                    name="arrow-right"
                    className="wallet-details-accordion__card--arrow"
                  />
                </summary>

                <div className="wallet-details-accordion__card--content">
                  <ReadOnlyField
                    label="Nome completo"
                    content={guarantor?.full_name}
                    className="wallet-details-accordion__field"
                  />

                  <div className="wallet-details-accordion__field--row">
                    <ReadOnlyField
                      label="CPF"
                      content={guarantor?.document_number}
                      className="wallet-details-accordion__field wallet-details-accordion__field--half"
                    />

                    <ReadOnlyField
                      label="Telefone"
                      content={guarantor?.phone_number}
                      className="wallet-details-accordion__field wallet-details-accordion__field--half"
                    />
                  </div>

                  <ReadOnlyField
                    label="Email"
                    content={guarantor?.email}
                    className="wallet-details-accordion__field"
                  />

                  <ReadOnlyField
                    label="Endereço"
                    content={guarantor?.address}
                    className="wallet-details-accordion__field"
                  />
                </div>
              </details>
            </If>

            <If condition={!!contract}>
              <details className="wallet-details-accordion__card">
                <summary className="wallet-details-accordion__card--title">
                  Contrato
                  <Icon
                    name="arrow-right"
                    className="wallet-details-accordion__card--arrow"
                  />
                </summary>
                <div className="wallet-details-accordion__card--content">
                  <div className="wallet-details-accordion__field--row">
                    <ReadOnlyField
                      label="Número do contrato"
                      content={contract?.number}
                      className="wallet-details-accordion__field wallet-details-accordion__field--half"
                    />

                    <ReadOnlyField
                      label="Situação do contrato"
                      content={contract?.status}
                      contentColor={contract?.status_color}
                      className="wallet-details-accordion__field wallet-details-accordion__field--half"
                    />
                  </div>

                  <div className="wallet-details-accordion__field--row">
                    <ReadOnlyField
                      label="Faixa de atraso"
                      content={contract?.delay_track_rating}
                      className="wallet-details-accordion__field wallet-details-accordion__field--half"
                    />

                    <ReadOnlyField
                      label="Dias de atraso"
                      content={contract?.amount_days_delay}
                      className="wallet-details-accordion__field wallet-details-accordion__field--half"
                    />
                  </div>

                  <div className="wallet-details-accordion__field--row">
                    <ReadOnlyField
                      label="Tipo de contrato"
                      content={contract?.type}
                      className="wallet-details-accordion__field wallet-details-accordion__field--half"
                    />

                    <ReadOnlyField
                      label="Data da contratação"
                      content={contract?.contract_date}
                      className="wallet-details-accordion__field wallet-details-accordion__field--half"
                    />
                  </div>

                  <div className="wallet-details-accordion__field--row">
                    <ReadOnlyField
                      label="Volume financiado"
                      content={contract?.amount}
                      className="wallet-details-accordion__field wallet-details-accordion__field--half"
                    />

                    <ReadOnlyField
                      label="Taxa de juros"
                      content={contract?.nominal_interest_rate}
                      className="wallet-details-accordion__field wallet-details-accordion__field--half"
                    />
                  </div>

                  <ReadOnlyField
                    label="Saldo devedor"
                    content={contract?.balance_due}
                    className="wallet-details-accordion__field"
                  />
                </div>
              </details>
            </If>
          </>
        }
        renderElse={
          <Card className="wallet-details-accordion__card">
            <EmptyState
              className="wallet-details-accordion__empty"
              text="Nada por aqui"
            />
          </Card>
        }
      />
    </div>
  )
}

export default WalletDetailsAccordion
